import { call, put, takeLatest } from "redux-saga/effects";
import { apiClient } from "../../api";
import { fetchWarehouseList, fetchWarehouseListSuccess, fetchWarehouseListFailure } from './warehouseSlice';

// 模拟数据
const mockData = {
    data: [
        {
            id: '1',
            orderNumber: '123456',
            orderDate: '2023-07-03',
            shipDate: '2023-07-03',
            profile: 'default',
            trackingNumber: '123456',
            carrier: 'API',
            method: 'Priority Line (Battery Goods) - YT',
            shippingName: 'FedEx',
            destCountry: 'USA',
            destZip: '12345',
            destState: 'CA',
            receiverName: 'John Doe',
        },
    ]
};

function* queryWarehouse(action) {
    try {
        const response = yield call(apiClient.get, '/warehouse/list', {});
        const responseBody = response.data;
        const { status, message, result } = responseBody;
        if (status === "fail") throw new Error(message);
        yield put(fetchWarehouseListSuccess(result));
    } catch (error) {
        yield put(fetchWarehouseListFailure(error.message));
    }
}


function* watchQueryWarehouse() {
    yield takeLatest(fetchWarehouseList.type, queryWarehouse);
}

export default watchQueryWarehouse;