import { call, put, takeLatest } from "redux-saga/effects";
import { apiClient } from "../../api";
import {
    fetchWarehouseProductList, fetchWarehouseProductListSuccess, fetchWarehouseProductListFailure,
    fetchWarehouseProductDetail, fetchWarehouseProductDetailSuccess, fetchWarehouseProductDetailFailure
} from './warehouseProductSlice';

function* queryWarehouseProducts(action) {
    try {
        let requestBody = { ...action.payload };
        if (requestBody && requestBody.pageIndex >= 1) {
            requestBody.pageIndex -=1;
        }

        const response = yield call(apiClient.post, '/warehouse_product/list', requestBody);
        const responseBody = response.data;
        const { status, message, result } = responseBody;
        if (status === "fail") throw new Error(message);
        result.pageIndex += 1;
        yield put(fetchWarehouseProductListSuccess(result));
    } catch (error) {
        yield put(fetchWarehouseProductListFailure(error.message));
    }
}

function* requestWarehouseProductDetail(action) {
    try {
        const response = yield call(apiClient.get, `/warehouse_product/detail/${action.payload}`);
        const responseBody = response.data;
        const { status, message, result } = responseBody;
        if (status === "fail") throw new Error(message);
        yield put(fetchWarehouseProductDetailSuccess(result));
    } catch (error) {
        yield put(fetchWarehouseProductDetailFailure(error.message));
    }
}

function* watchFetchWarehouseProduct() {
    yield takeLatest(fetchWarehouseProductList.type, queryWarehouseProducts);
    yield takeLatest(fetchWarehouseProductDetail.type, requestWarehouseProductDetail);
}

export default watchFetchWarehouseProduct;