import { call, put, takeLatest } from "redux-saga/effects";
import { apiClient } from "../../api";
import {
    getClientList, getClientListSuccess, getClientListFailure,
    getClientDetail, getClientDetailSuccess, getClientDetailFailure,
} from './clientSlice';



function* requestClientList(action) {
    try {
        let requestBody = { ...action.payload };
        if (requestBody && requestBody.pageIndex >= 1) {
            requestBody.pageIndex -=1;
        }
        const response = yield call(apiClient.post, '/customer/list', requestBody);
        const responseBody = response.data;
        const { status, message, result } = responseBody;
        if (status === "fail") throw new Error(message);
        result.pageIndex += 1;
        yield put(getClientListSuccess(result));
    } catch (error) {
        yield put(getClientListFailure(error.message));
    }
}

function* requestClientDetail(action) {
    try {
        const response = yield call(apiClient.get, `/customer/detail/${action.payload}`);
        const responseBody = response.data;
        const { status, message, result } = responseBody;
        if (status === "fail") throw new Error(message);
        yield put(getClientDetailSuccess(result));
    } catch (error) {
        yield put(getClientDetailFailure(error.message));
    }
}

function* watchClient() {
    yield takeLatest(getClientList.type, requestClientList);
    yield takeLatest(getClientDetail.type, requestClientDetail);

}

export default watchClient;