// src/features/rateInquiry/rateInquirySaga.js
import { takeLatest, put, call, all } from "redux-saga/effects";
import { setResultRequest, updateResult } from "./rateInquirySlice";
import { apiClient } from "../../api";

const constructQueryParams = (state) => {
  const { dimensionUnit, weightUnit, rows } = state;
  if (!rows || rows.length === 0) {
    throw new Error(
      "No rows available in the state to construct query parameters"
    );
  }

  // Iterate over rows and construct query string for each row
  return rows.map(({ zipCode, length, width, height, weight, residential }) => {
    return `?zipCode=${encodeURIComponent(zipCode)}&length=${encodeURIComponent(
      length
    )}&width=${encodeURIComponent(width)}&height=${encodeURIComponent(
      height
    )}&weight=${encodeURIComponent(weight)}&dimensionUnit=${encodeURIComponent(
      dimensionUnit
    )}&weightUnit=${encodeURIComponent(weightUnit)}&residential=${encodeURIComponent(residential)}`;
  });
};

function* hanldeSubmit(action) {
  try {
    const queryParamsArray = constructQueryParams(action.payload.state);
    const responses = yield all(
      queryParamsArray.map((queryParams) =>
        call(
          apiClient.get,
          `/shipheroWebhook/getubiUSRates${queryParams}`
        )
      )
    );

    // Extract data from each response
    const data = responses.map((response) => response.data);

    // Combine inputs with their corresponding results
    const finalResult = action.payload.state.rows.map((row, index) => ({
      input: row,
      result: data[index],
    }));
    finalResult.weightUnit = action.payload.state.weightUnit;
    finalResult.dimensionUnit = action.payload.state.dimensionUnit;

    yield put(updateResult(finalResult));
  } catch (error) {
    // some error handling
  }
}

function* watchSubmit() {
  yield takeLatest(setResultRequest.type, hanldeSubmit);
}

// Root saga
export default watchSubmit;
