import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  manifestShipmentListRequest, // Action to request the shipment list
  manifest, // Action to manifest selected shipments
  toggleRowSelection, // Action to select/deselect a row
  toggleSelectAll, // Action to select/deselect all rows
  updateFilters, // Action to update filter state
} from "../../features/ubiManifest/ubiManifestActions";
import { Table, DatePicker, Row, Col, Button, Checkbox, Select } from "antd";
import styled from "styled-components";
import NavBar from "../../components/navBar"; // Navigation bar component
import useDocumentTitle from "../../hooks/useDocumentTitle"; // Hook for setting document title
import moment from "moment"; // Library for date manipulation

const { RangePicker } = DatePicker; // Destructuring RangePicker from DatePicker for date selection
const { Option } = Select; // Destructuring Option for select component

const UBIManifestShipmentList = () => {
  // Set the document title to "UBI Shipment Manifest - ODIN"
  useDocumentTitle("UBI Shipment Manifest - ODIN");

  const dispatch = useDispatch(); // Redux dispatch function to trigger actions

  // Extract necessary state from Redux store
  const { shipmentList, loading, filters, buttonLoading } = useSelector(
    (state) => state.ubiManifest
  );

  // Effect hook to fetch the initial shipment list with default filter settings
  useEffect(() => {
    dispatch(
      manifestShipmentListRequest({
        shipDateStart: null,
        shipDateEnd: null,
        shipmentType: "unmanifested", // Default to unmanifested shipments
      })
    );
  }, [dispatch]);

  // Handle changes in ship date filter (RangePicker)
  const handleShipDateChange = (dates, dateStrings) => {
    const [start, end] = dateStrings; // Extract start and end date strings
    const updatedFilter = {
      ...filters,
      shipDateStart: start ? moment(start).format("YYYY-MM-DD") : null, // Format start date
      shipDateEnd: end ? moment(end).format("YYYY-MM-DD") : null, // Format end date
    };
    dispatch(updateFilters(updatedFilter)); // Update filters in Redux store
    dispatch(manifestShipmentListRequest(updatedFilter)); // Fetch updated shipment list
  };

  // Handle changes in shipment type filter (Select component)
  const handleshipmentTypeChange = (value) => {
    const updateFilter = { ...filters, shipmentType: value }; // Update shipment type in filters
    dispatch(updateFilters(updateFilter)); // Dispatch updated filters
    dispatch(manifestShipmentListRequest(updateFilter)); // Fetch updated shipment list
  };

  // Handle "select all" checkbox state change
  const handleSelectAllChange = (e) => {
    dispatch(toggleSelectAll(e.target.checked)); // Select/deselect all shipments
  };

  // Handle individual row selection checkbox state change
  const handleRowSelectionChange = (trackingNumber) => {
    dispatch(toggleRowSelection({ trackingNumber })); // Toggle selection for a specific row
  };

  // Handle manifest button click
  const handleManifest = () => {
    // Filter selected and unmanifested shipments
    const selectedShipments = shipmentList.data.filter(
      (shipment) => shipment.isChecked && !shipment.is_manifested
    );
    dispatch(manifest({ shipmentList: selectedShipments })); // Dispatch action to manifest selected shipments
    dispatch(manifestShipmentListRequest(filters)); // Refetch shipment list after manifesting
  };

  // Define table columns configuration
  const columns = [
    {
      title: (
        <Checkbox
          onChange={handleSelectAllChange} // Handle "select all" checkbox click
          checked={
            shipmentList.data.length >= 1 && // Check if all rows are selected
            shipmentList.data.every((shipment) => shipment.isChecked)
          }
          indeterminate={
            shipmentList.data.some((shipment) => shipment.isChecked) && // Indeterminate state if only some rows are selected
            !shipmentList.data.every((shipment) => shipment.isChecked)
          }
          disabled={filters.shipmentType === "manifested"} // Disable "select all" for manifested shipments
        />
      ),
      dataIndex: "isChecked", // Reference to isChecked in the data
      key: "isChecked",
      width: 40,
      render: (text, record) => (
        <Checkbox
          checked={record.isChecked} // Checkbox for individual row
          onChange={() => handleRowSelectionChange(record.trackingNumber)} // Handle row selection
          disabled={
            filters.shipmentType === "manifested" || // Disable for manifested shipments
            (filters.shipmentType === "all" && record.is_manifested) // Disable if already manifested in "all" view
          }
        />
      ),
    },
    {
      title: "Order Number", // Column for order number
      width: 140,
      fixed: "left", // Fix column to the left
      dataIndex: "orderNumber", // Reference to orderNumber in the data
      key: "orderNumber",
    },
    {
      title: "Order Date", // Column for order date
      width: 120,
      dataIndex: "orderDate",
      key: "orderDate",
    },
    {
      title: "Tracking Number", // Column for tracking number
      width: 300,
      key: "trackingNumber",
      dataIndex: "trackingNumber",
    },
    {
      title: "Manifested", // Column to display if shipment is manifested
      width: 120,
      key: "is_manifested",
      dataIndex: "is_manifested",
      render: (is_manifested) => (is_manifested ? "Yes" : "No"), // Render "Yes" or "No"
    },
  ];

  return (
    <div>
      <NavBar /> {/* Navigation bar component */}
      <h1>UBI Shipment Manifest</h1>
      <div style={{ padding: 20 }}>
        <Row gutter={16}>
          <Col span={5}>
            <RangePicker
              placeholder={["Ship Date From", "Ship Date To"]} // Placeholder for date picker
              allowEmpty={[true, true]} // Allow empty date fields
              onChange={handleShipDateChange} // Handle date change
            />
          </Col>
          <Col span={5}>
            <Select
              placeholder="Display"
              value={filters.shipmentType || undefined} // Bind shipment type filter to Select component
              onChange={handleshipmentTypeChange} // Handle shipment type change
              style={{ width: "100%" }}
            >
              <Option value="unmanifested">Un-Manifested Shipments</Option>
              <Option value="manifested">Manifested Shipments</Option>
              <Option value="all">All Shipments</Option>
            </Select>
          </Col>
        </Row>
        <StyledTable>
          <Table
            className="table-striped-rows" // Apply striped rows style
            columns={columns} // Pass columns configuration
            dataSource={shipmentList.data} // Bind data to the table
            loading={loading || buttonLoading} // Display loading state
            bordered
            rowKey="trackingNumber" // Unique key for each row
            pagination={{
              current: shipmentList.pageIndex, // Current page index
              pageSize: shipmentList.pageSize, // Number of items per page
              total: shipmentList.total, // Total number of items
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`, // Display pagination info
              onChange: (pageIndex, pageSize) => {
                dispatch(
                  manifestShipmentListRequest({
                    ...filters,
                    pageIndex, // Change page index
                    pageSize, // Change page size
                  })
                );
              },
            }}
            style={{ marginTop: 20 }}
          />
          <Button
            title="Manifest"
            type="primary"
            onClick={handleManifest} // Handle manifest button click
            disabled={
              !shipmentList.data.some((shipment) => shipment.isChecked) || // Disable if no shipments are selected
              buttonLoading // Disable if already loading
            }
          >
            Manifest
          </Button>
        </StyledTable>
      </div>
    </div>
  );
};

// Styled component for table with striped rows
const StyledTable = styled.div`
  .table-striped-rows tr:nth-child(2n) td {
    background-color: #fbfbfb;
  }
  .table-striped-rows thead {
    background-color: #f1f1f1;
  }
`;

export default UBIManifestShipmentList;
