import { call, put, select, takeLatest } from "redux-saga/effects";
import { apiClient } from "../../api";
import {
  manifestShipmentListRequest,
  manifestShipmentListSuccess,
  manifestShipmentListFailure,
  manifest,
  manifestSuccess,
  manifestFailure,
} from "./ubiManifestSlice";
import { notify } from "../../utils/notifications";

function* queryManifestShipment(action) {
  try {
    let requestBody = { ...action.payload };
    if (requestBody && requestBody.pageIndex >= 1) {
      requestBody.pageIndex -= 1;
    }
    const response = yield call(
      apiClient.post,
      "/ubi/manifestShipmentList",
      requestBody
    );
    const responseBody = response.data;
    const { status, message, result } = responseBody;
    if (status === "fail") throw new Error(message);
    result.pageIndex += 1;
    yield put(manifestShipmentListSuccess(result));
  } catch (error) {
    yield put(manifestShipmentListFailure(error.message));
  }
}

function* manifestShipment(action) {
  try {
    const { shipmentList } = action.payload;
    const requestBody = {
      trackingNumbers: shipmentList.map((shipment) => shipment.trackingNumber),
    };

    const response = yield call(
      apiClient.post,
      "/ubi/manifestShipment",
      requestBody
    );
    const responseBody = response.data;
    const { status, message } = responseBody;

    if (status === "fail") {
      notify("error", `Manifest Failed due to: ${message}`, 5);
      throw new Error(message);
    }

    yield put(manifestSuccess());
    notify("success", "Shipments Manifested Successfully", 5);

    // Wait for the manifest to complete before fetching the shipment list again
    const filters = yield select((state) => state.ubiManifest.filters);
    yield put(manifestShipmentListRequest(filters));
  } catch (error) {
    yield put(manifestFailure(error.message));
  }
}

export default function* ubiManifestSaga() {
  yield takeLatest(manifestShipmentListRequest.type, queryManifestShipment);
  yield takeLatest(manifest.type, manifestShipment);
}
