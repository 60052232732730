import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {
    shipDateStart: null,
    shipDateEnd: null,
    shipmentType: "unmanifested",
  },
  shipmentList: {
    data: [],
    pageIndex: 1,
    pageSize: 10,
    total: 0,
  },
  shipmentDetail: null,
  loading: false,
  buttonLoading: false,
  error: null,
};

const ubiManifestSlice = createSlice({
  name: "ubiManifest",
  initialState,
  reducers: {
    manifestShipmentListRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.filters = {
        ...action.payload,
        shipmentType: action.payload.shipmentType,
      };
    },
    manifestShipmentListSuccess: (state, action) => {
      state.loading = false;
      state.shipmentList = {
        ...action.payload,
        data: action.payload.data.map((shipment) => ({
          ...shipment,
          isChecked: false, // Add isChecked attribute
        })),
      };
    },
    manifestShipmentListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    toggleRowSelection: (state, action) => {
      const { trackingNumber } = action.payload;
      state.shipmentList.data = state.shipmentList.data.map((shipment) =>
        shipment.trackingNumber === trackingNumber
          ? { ...shipment, isChecked: !shipment.isChecked }
          : shipment
      );
    },
    toggleSelectAll: (state, action) => {
      state.shipmentList.data = state.shipmentList.data.map((shipment) =>
        shipment.is_manifested
          ? shipment
          : { ...shipment, isChecked: !shipment.isChecked }
      );
    },
    manifest: (state, action) => {
      state.buttonLoading = true;
    },
    manifestSuccess: (state, action) => {
      state.buttonLoading = false;
    },
    manifestFailure: (state, action) => {
      state.buttonLoading = false;
    },
    updateFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
  },
});

export const {
  manifestShipmentListRequest,
  manifestShipmentListSuccess,
  manifestShipmentListFailure,
  toggleRowSelection,
  toggleSelectAll,
  manifest,
  manifestSuccess,
  manifestFailure,
  updateFilters,
} = ubiManifestSlice.actions;

export default ubiManifestSlice.reducer;
